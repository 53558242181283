import Vue from 'vue'

const RoomFacilities = () => import(/* webpackChunkName: "room-facilities" */ '@/components/room/RoomFacilities.vue')
const RoomList = () => import(/* webpackChunkName: "room-list" */ '@/components/room/RoomList.vue')
const Calendar = () => import(/* webpackChunkName: "calendar" */ '@/components/Calendar.vue')

const Auth = () => import(/* webpackChunkName: "auth" */ '@/components/Auth.vue')
const Toast = () => import(/* webpackChunkName: "toast" */ '@/components/Toast.vue')
const Tags = () => import(/* webpackChunkName: "tags" */ '@/components/Tags.vue')
const Map = () => import(/* webpackChunkName: "map" */ '@/components/Map.vue')
const ScanBarcode = () => import(/* webpackChunkName: "scan-barcode" */ '@/components/ScanBarcode.vue')
const FeatureBanner = () => import(/* webpackChunkName: "feature-banner" */ '@/components/features/Banner.vue')
const XlsxImport = () => import(/* webpackChunkName: "xlsx" */ '@/components/XlsxImport.vue')
const XlsxExport = () => import(/* webpackChunkName: "xlsx" */ '@/components/XlsxExport.vue')

Vue.component('RoomList', RoomList)
Vue.component('RoomFacilities', RoomFacilities)
Vue.component('Calendar', Calendar)
Vue.component('Auth', Auth)
Vue.component('Toast', Toast)
Vue.component('Tags', Tags)
Vue.component('ScanBarcode', ScanBarcode)
Vue.component('Map', Map)
Vue.component('FeatureBanner', FeatureBanner)
Vue.component('XlsxImport', XlsxImport)
Vue.component('XlsxExport', XlsxExport)
