import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import(/* webpackChunkName: "member" */ '../views/Member.vue')
  },
  {
    path: '/account-manager',
    name: 'AccountManager',
    component: () => import(/* webpackChunkName: "account-manager" */ '../views/AccountManager.vue')
  },
  {
    path: '/store-manager',
    name: 'StoreManager',
    component: () => import(/* webpackChunkName: "store-manager" */ '../views/StoreManager.vue')
  },
  {
    path: '/features/:page',
    name: 'Banner',
    component: () => import(/* webpackChunkName: "feature" */ '../views/features/_index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
